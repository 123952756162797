<script setup lang="ts">
import type { SearchDocument } from '~/src/search.ts';

const props = defineProps<{
    document: SearchDocument;
}>();

const iconBySearchKind: Record<SearchDocument['kind'], string> = {
    locale: 'language',
    page: 'file',
    pronoun: 'tags',
    noun: 'book',
    source: 'books',
    link: 'bookmark',
    faq: 'map-marker-question',
    blog: 'pen-nib',
    term: 'flag',
    inclusive: 'book-heart',
    calendar: 'calendar-star',
};

const icon = computed(() => {
    return iconBySearchKind[props.document.kind];
});

const searchKindsWithImage: SearchDocument['kind'][] = ['source', 'blog', 'term', 'calendar'];

const searchKindHasImage = computed(() => {
    return searchKindsWithImage.includes(props.document.kind);
});
</script>

<template>
    <nuxt-link :to="document.url" class="search-item d-block p-3 text-dark text-decoration-none">
        <div class="h3 mb-3">
            <Icon :v="icon" />
            <Spelling :text="document.title" />
        </div>
        <div v-if="searchKindHasImage" class="d-flex">
            <div class="col-4 col-md-2">
                <img
                    v-if="document.image"
                    :src="document.image.src"
                    :class="['w-100 pe-2', document.image.class]"
                    :alt="document.image.alt"
                    loading="lazy"
                >
                <Icon v-else-if="document.icon" :v="document.icon" class="h3" />
            </div>
            <div class="col">
                <Spelling :text="document.content" />
                <ul v-if="document.date || document.authors" class="list-inline mb-0 small">
                    <li class="list-inline-item small">
                        <Icon v="calendar" />
                        {{ document.date }}
                    </li>
                    <li v-for="author in document.authors" :key="author" class="list-inline-item">
                        <span v-if="author.startsWith('@')" class="badge bg-light text-dark border">
                            <Icon v="collective-logo.svg" class="invertible" />
                            {{ author }}
                        </span>
                        <span v-else class="badge bg-light text-dark border">
                            {{ author }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <Spelling v-else :text="document.content" />
    </nuxt-link>
</template>

<style scoped lang="scss">
.search-item {
    margin-inline-start: -1rem;
}
</style>
